import React, { useState, useEffect } from "react";
import { ColorModeContext, useMode } from "./themes";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import AllUsers from "./scenes/allUsers";
import Form from "./scenes/form";
import EditForm from "./scenes/editForm";
import Dashboard from "./scenes/dashboard";
import InTransit from "./scenes/in-transit";
import OnHold from "./scenes/on-hold";
import Arrived from "./scenes/arrived";
import Register from "./scenes/global/Register";
import Login from "./scenes/global/Login";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );
  const [theme, colorMode] = useMode();

  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div>
          <Routes>
            <Route path="/" element={<Register />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
          </Routes>
        </div>

        {isLoggedIn && (
          <div className="app">
            <Sidebar onLogout={handleLogout} />
            <main className="content">
              <Topbar />

              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/list" element={<AllUsers />} />
                <Route path="/create" element={<Form />} />
                <Route path="/edit/:id" element={<EditForm />} />
                <Route path="/in-transit" element={<InTransit />} />
                <Route path="/on-hold" element={<OnHold />} />
                <Route path="/arrived" element={<Arrived />} />
              </Routes>
            </main>
          </div>
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
