import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography, MenuItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { BASE_URL } from "../../apiConfig";

const EditForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(min-width:600px)");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${BASE_URL}/edit/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    console.log(data);
    await fetch(`${BASE_URL}/edit/${id}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        console.log(response);
        response.json();
        navigate("/dashboard");
      })
      .catch((error) => {
        alert(`This ${error} just occurred. Please try again`);
      });
  };

  return (
    <Box m="20px">
      <Header title="Edit Tracking Info" />
      {loading ? (
        <Box>Loading...</Box>
      ) : (
        <form>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isMobile ? undefined : "span 4" },
            }}
          >
            <Typography variant="h4">SENDER DETAILS</Typography>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Senders Name"
              value={data.sendersName}
              name="sendersName"
              onChange={handleChange}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Senders Contact"
              value={data.sendersContact}
              name="sendersContact"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="email"
              label="Senders Email"
              value={data.sendersEmail}
              name="sendersEmail"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Senders Address"
              value={data.sendersAddress}
              name="sendersAddress"
              onChange={handleChange}
              sx={{ gridColumn: "span 4" }}
            />
            <Typography variant="h4">RECIEVER DETAILS</Typography>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Receiver Name"
              value={data.recieverName}
              name="recieverName"
              onChange={handleChange}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Receiver Contact"
              value={data.recieverContact}
              name="recieverContact"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="email"
              label="Receiver Email"
              value={data.recieverEmail}
              name="recieverEmail"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Receiver Address"
              value={data.recieverAddress}
              name="recieverAddress"
              onChange={handleChange}
              sx={{ gridColumn: "span 4" }}
            />
            <Typography variant="h4">ITEM(S) DESCRIPTION</Typography>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Item Description(Package)"
              value={data.itemDesc}
              name="itemDesc"
              onChange={handleChange}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Product Name"
              value={data.productName}
              name="productName"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Weight"
              value={data.weight}
              name="weight"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Shipment Mode"
              value={data.shipmentMode}
              name="shipmentMode"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Type of Shipment"
              value={data.shipmentType}
              name="shipmentType"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Payment Mode"
              value={data.paymentMode}
              name="paymentMode"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Quantity"
              value={data.qty}
              name="qty"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Total Freight"
              value={data.totalFreight}
              name="totalFreight"
              onChange={handleChange}
              sx={{ gridColumn: "span 4" }}
            />
            <Typography variant="h4">
              PARCEL AND CONSIGNMENT TRANSITS
            </Typography>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Original Country"
              value={data.originalCountry}
              name="originalCountry"
              onChange={handleChange}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="date"
              label="Estimated Delivery Date"
              value={data.estimatedDeliveryDate}
              name="estimatedDeliveryDate"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              id="select"
              label="Status"
              name="status"
              value={data.status}
              select
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            >
              <MenuItem value="on Hold">on Hold</MenuItem>
              <MenuItem value="In Transit">In Transit</MenuItem>
              <MenuItem value="Arrived">Arrived</MenuItem>
            </TextField>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Consignment Movement"
              value={data.customStatus}
              name="customStatus"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Destination"
              value={data.destination}
              name="destination"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="datetime-local"
              label="Date/Time Sent"
              value={data.sentDateTime}
              name="sentDateTime"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="time"
              label="Pickup Time"
              value={data.pickupTime}
              name="pickupTime"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="date"
              label="Pickup Date"
              value={data.pickupDate}
              name="pickupDate"
              onChange={handleChange}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              variant="filled"
              type="text"
              label="Comments"
              placeholder="Comments..."
              value={data.comments}
              name="comments"
              onChange={handleChange}
              sx={{ gridColumn: "span 4" }}
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              onClick={handleEdit}
            >
              Update User
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
};

export default EditForm;
