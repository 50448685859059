import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Box, Button } from "@mui/material";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../apiConfig";

const Dashboard = () => {
  const [data, setData] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchDashboard = async () => {
      await fetch(`${BASE_URL}/dashboard`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data);
        });
    };
    fetchDashboard();
  }, []);

  return (
    <Box m="20px">
      <Header title="DASHBOARD" subtitle="Welcome to the Dashboard" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              backgroundColor: "#C21010",
            }}
          >
            <CardContent
              sx={{
                color: "#fff",
              }}
            >
              <h2>{data.data?.productInTransit}</h2>
              <p>IN TRANSIT</p>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/in-transit");
                }}
              >
                More Info
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              backgroundColor: "#FFC93C",
            }}
          >
            <CardContent
              sx={{
                color: "#fff",
              }}
            >
              <h2>{data.data?.productOnHold}</h2>
              <p>ON HOLD</p>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/on-hold");
                }}
              >
                More Info
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              backgroundColor: "#5BC0F8",
            }}
          >
            <CardContent
              sx={{
                color: "#fff",
              }}
            >
              <h2>{data.data?.arrived}</h2>
              <p>ARRIVED</p>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/arrived");
                }}
              >
                More Info
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              backgroundColor: "#54B435",
            }}
          >
            <CardContent
              sx={{
                color: "#fff",
              }}
            >
              <h2>{data.data?.allUsers}</h2>
              <p>ALL USERS</p>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/list");
                }}
              >
                More Info
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
