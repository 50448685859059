import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Dialog, Slide, AppBar, Toolbar, IconButton } from "@mui/material";
import "../scenes/form/Tracking.css";
import Logo from "../assets/Goxpress_logo1.png";
import { QRCodeSVG } from "qrcode.react";
import CloseIcon from "@mui/icons-material/Close";

const PrintModal = ({ selectedRow, handleClose, isModalOpen }) => {
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
	});

	return (
		<Dialog
			fullScreen
			open={isModalOpen}
			onClose={handleClose}
			TransitionComponent={Transition}>
			<AppBar sx={{ position: "relative" }}>
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						onClick={handleClose}
						aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<section className="app__tracking-info-cont">
				<div ref={componentRef} className="tracking-info-container">
					<div className="tracking-info-header">
						<div className="company-logo">
							<img src={Logo} alt="logo" width="100px" />
						</div>
						<div className="tracking-id">
							Tracking ID: {selectedRow.trackId}
						</div>
					</div>
					<span
						style={{
							display: "block",
							paddingTop: "3rem",
							margin: "auto",
							fontSize: "1rem",
						}}>
						{selectedRow.comments}
					</span>
					<div className="tracking-info-body">
						<div className="tracking-info-section contact-info">
							<h3>Sender Information</h3>
							<div className="tracking-info-row">
								<div className="tracking-info-label">Name:</div>
								<div className="tracking-info-value">
									{selectedRow.sendersName}
								</div>
							</div>
							<div className="tracking-info-row">
								<div className="tracking-info-label">Contact:</div>
								<div className="tracking-info-value">
									{selectedRow.sendersContact}
								</div>
							</div>
							<div className="tracking-info-row">
								<div className="tracking-info-label">Email:</div>
								<div className="tracking-info-value">
									{selectedRow.sendersEmail}
								</div>
							</div>
							<div className="tracking-info-row">
								<div className="tracking-info-label">Address:</div>
								<div className="tracking-info-value">
									{selectedRow.sendersAddress}
								</div>
							</div>
						</div>
						<div className="tracking-info-section contact-info">
							<h3>Receiver Information</h3>
							<div className="tracking-info-row">
								<div className="tracking-info-label">Name:</div>
								<div className="tracking-info-value">
									{selectedRow.recieverName}
								</div>
							</div>
							<div className="tracking-info-row">
								<div className="tracking-info-label">Contact:</div>
								<div className="tracking-info-value">
									{selectedRow.recieverContact}
								</div>
							</div>
							<div className="tracking-info-row">
								<div className="tracking-info-label">Email:</div>
								<div className="tracking-info-value">
									{selectedRow.recieverEmail}
								</div>
							</div>
							<div className="tracking-info-row">
								<div className="tracking-info-label">Address:</div>
								<div className="tracking-info-value">
									{selectedRow.recieverAddress}
								</div>
							</div>
						</div>
						<div className="tracking-info-section">
							<h3>Shipment Information</h3>
							<div className="tracking-info-row">
								<div className="tracking-info-label">Item Description:</div>
								<div className="tracking-info-value">
									{selectedRow.itemDesc}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Product Name:</div>
								<div className="tracking-info-value">
									{selectedRow.productName}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Weight:</div>
								<div className="tracking-info-value">{selectedRow.weight}</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Shipment Mode:</div>
								<div className="tracking-info-value">
									{selectedRow.shipmentMode}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Shipment Type:</div>
								<div className="tracking-info-value">
									{selectedRow.shipmentType}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Payment Mode:</div>
								<div className="tracking-info-value">
									{selectedRow.paymentMode}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Quantity:</div>
								<div className="tracking-info-value">{selectedRow.qty}</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Total Freight:</div>
								<div className="tracking-info-value">
									{selectedRow.totalFreight}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Original Country:</div>
								<div className="tracking-info-value">
									{selectedRow.originalCountry}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Destination:</div>
								<div className="tracking-info-value">
									{selectedRow.destination}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Consignment Movement:</div>
								<div className="tracking-info-value">
									{selectedRow.customStatus}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">
									Estimated Delivery Date:
								</div>
								<div className="tracking-info-value">
									{selectedRow.estimatedDeliveryDate}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Sent Date/Time:</div>
								<div className="tracking-info-value">
									{selectedRow.sentDateTime}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Pickup Time:</div>
								<div className="tracking-info-value">
									{selectedRow.pickupTime}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Pickup Date:</div>
								<div className="tracking-info-value">
									{selectedRow.pickupDate}
								</div>
							</div>
							<hr />
							<div className="tracking-info-row">
								<div className="tracking-info-label">Comments:</div>
								<div className="tracking-info-value">
									{selectedRow.comments}
								</div>
							</div>
							<hr />
						</div>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							paddingBottom: "2rem",
							paddingTop: "2rem",
						}}>
						<QRCodeSVG
							value="https://goxpressway.com/"
							size={170}
							imageSettings={{
								src: `${Logo}`,
								width: 100,
							}}
						/>
					</div>
					<button onClick={handlePrint}>Click to Print</button>
				</div>
			</section>
		</Dialog>
	);
};

export default PrintModal;
