import React, { useState, useEffect, useRef, useCallback } from "react";
import {
	Box,
	Button,
	TextField,
	Typography,
	MenuItem,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	IconButton,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../apiConfig";
import CloseIcon from "@mui/icons-material/Close";
import "./Tracking.css";
import Logo from "../../assets/Goxpress_logo1.png";
import { useReactToPrint } from "react-to-print";
import { QRCodeSVG } from "qrcode.react";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Form = () => {
	const navigate = useNavigate();
	const isMobile = useMediaQuery("(min-width:600px)");
	const [response, setResponse] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalResult, setModalResult] = useState(null);
	const [initialValues, setInitialValues] = useState({
		sendersName: "",
		sendersContact: "",
		sendersEmail: "",
		sendersAddress: "",
		recieverName: "",
		recieverContact: "",
		recieverEmail: "",
		recieverAddress: "",
		status: "",
		itemDesc: "",
		productName: "",
		weight: "",
		shipmentMode: "",
		shipmentType: "",
		paymentMode: "",
		qty: "",
		totalFreight: "",
		originalCountry: "",
		destination: "",
		estimatedDeliveryDate: "",
		pickupTime: "",
		sentDateTime: "",
		pickupDate: "",
		customStatus: "",
		comments: "",
	});

	const openModal = useCallback(() => {
		setModalOpen(true);
	}, []);

	const closeModal = () => {
		setModalOpen(false);
		navigate("/dashboard");
	};

	const handleChange = (event) => {
		setInitialValues({
			...initialValues,
			[event.target.name]: event.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const inputData = initialValues;
		await fetch(`${BASE_URL}/create`, {
			method: "POST",
			body: JSON.stringify(inputData),
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setResponse(data.data);

				fetch(`${BASE_URL}/getid`, {
					method: "POST",
					body: JSON.stringify({ id: data.data }),
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						"Access-Control-Allow-Origin": "*",
					},
				})
					.then((response) => response.json())
					.then((data) => {
						const result = data.data;
						setModalResult(result);
					});
			});
	};
	useEffect(() => {
		if (modalResult) {
			openModal();
		}
	}, [modalResult, openModal]);

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<Box m="20px">
			<Header
				title="CREATE NEW TRACKING"
				subtitle={response && response.data}
			/>

			<form>
				<Box
					display="grid"
					gap="30px"
					gridTemplateColumns="repeat(4, minmax(0, 1fr))"
					sx={{
						"& > div": { gridColumn: isMobile ? undefined : "span 4" },
					}}>
					<Typography variant="h4">SENDER DETAILS</Typography>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Senders Name"
						value={initialValues.sendersName}
						name="sendersName"
						onChange={handleChange}
						sx={{ gridColumn: "span 4" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Senders Contact"
						value={initialValues.sendersContact}
						name="sendersContact"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="email"
						label="Senders Email"
						value={initialValues.sendersEmail}
						name="sendersEmail"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Senders Address"
						value={initialValues.sendersAddress}
						name="sendersAddress"
						onChange={handleChange}
						sx={{ gridColumn: "span 4" }}
					/>
					<Typography variant="h4">RECIEVER DETAILS</Typography>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Receiver Name"
						value={initialValues.recieverName}
						name="recieverName"
						onChange={handleChange}
						sx={{ gridColumn: "span 4" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Receiver Contact"
						value={initialValues.recieverContact}
						name="recieverContact"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="email"
						label="Receiver Email"
						value={initialValues.recieverEmail}
						name="recieverEmail"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Receiver Address"
						value={initialValues.recieverAddress}
						name="recieverAddress"
						onChange={handleChange}
						sx={{ gridColumn: "span 4" }}
					/>
					<Typography variant="h4">ITEM(S) DESCRIPTION</Typography>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Item Description(Package)"
						value={initialValues.itemDesc}
						name="itemDesc"
						onChange={handleChange}
						sx={{ gridColumn: "span 4" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Product Name"
						value={initialValues.productName}
						name="productName"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Weight"
						value={initialValues.weight}
						name="weight"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Shipment Mode"
						value={initialValues.shipmentMode}
						name="shipmentMode"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Type of Shipment"
						value={initialValues.shipmentType}
						name="shipmentType"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Payment Mode"
						value={initialValues.paymentMode}
						name="paymentMode"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Quantity"
						value={initialValues.qty}
						name="qty"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Total Freight"
						value={initialValues.totalFreight}
						name="totalFreight"
						onChange={handleChange}
						sx={{ gridColumn: "span 4" }}
					/>
					<Typography variant="h4">PARCEL AND CONSIGNMENT TRANSITS</Typography>
					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Original Country"
						value={initialValues.originalCountry}
						name="originalCountry"
						onChange={handleChange}
						sx={{ gridColumn: "span 4" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="date"
						label="Estimated Delivery Date"
						value={initialValues.estimatedDeliveryDate}
						name="estimatedDeliveryDate"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						id="select"
						label="Status"
						name="status"
						value={initialValues.status}
						select
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}>
						<MenuItem value="on Hold">on Hold</MenuItem>
						<MenuItem value="In Transit">In Transit</MenuItem>
						<MenuItem value="Arrived">Arrived</MenuItem>
					</TextField>

					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Consignment Movement"
						value={initialValues.customStatus}
						name="customStatus"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>

					<TextField
						fullWidth
						variant="filled"
						type="text"
						label="Destination"
						value={initialValues.destination}
						name="destination"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="datetime-local"
						label="Date/Time Sent"
						value={initialValues.sentDateTime}
						name="sentDateTime"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="time"
						label="Pickup Time"
						value={initialValues.pickupTime}
						name="pickupTime"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						fullWidth
						variant="filled"
						type="date"
						label="Pickup Date"
						value={initialValues.pickupDate}
						name="pickupDate"
						onChange={handleChange}
						sx={{ gridColumn: "span 2" }}
					/>
					<TextField
						variant="filled"
						type="text"
						label="Comments"
						placeholder="Comments..."
						value={initialValues.comments}
						name="comments"
						onChange={handleChange}
						sx={{ gridColumn: "span 4" }}
					/>
				</Box>
				<Box display="flex" justifyContent="end" mt="20px">
					<Button
						type="submit"
						color="secondary"
						variant="contained"
						onClick={handleSubmit}>
						Create New User
					</Button>
				</Box>
			</form>

			{modalOpen && (
				<Dialog
					fullScreen
					open={modalOpen}
					onClose={closeModal}
					TransitionComponent={Transition}>
					<AppBar sx={{ position: "relative" }}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={closeModal}
								aria-label="close">
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<section className="app__tracking-info-cont">
						<div ref={componentRef} className="tracking-info-container">
							<div className="tracking-info-header">
								<div className="company-logo">
									<img src={Logo} alt="logo" width="100px" />
								</div>
								<div className="tracking-info-value">Consignor's copy.</div>
								<div className="tracking-id">
									Tracking ID: {modalResult?.trackId}
								</div>
							</div>
							<span
								style={{
									display: "block",
									paddingTop: "3rem",
									margin: "auto",
									fontSize: "1rem",
								}}>
								{modalResult?.comments}
							</span>
							<div className="tracking-info-body">
								<div className="tracking-info-section contact-info">
									<h3>Sender Information</h3>
									<div className="tracking-info-row">
										<div className="tracking-info-label">Name:</div>
										<div className="tracking-info-value">
											{modalResult?.sendersName}
										</div>
									</div>
									<div className="tracking-info-row">
										<div className="tracking-info-label">Contact:</div>
										<div className="tracking-info-value">
											{modalResult?.sendersContact}
										</div>
									</div>
									<div className="tracking-info-row">
										<div className="tracking-info-label">Email:</div>
										<div className="tracking-info-value">
											{modalResult?.sendersEmail}
										</div>
									</div>
									<div className="tracking-info-row">
										<div className="tracking-info-label">Address:</div>
										<div className="tracking-info-value">
											{modalResult?.sendersAddress}
										</div>
									</div>
								</div>
								<div className="tracking-info-section contact-info">
									<h3>Receiver Information</h3>
									<div className="tracking-info-row">
										<div className="tracking-info-label">Name:</div>
										<div className="tracking-info-value">
											{modalResult?.recieverName}
										</div>
									</div>
									<div className="tracking-info-row">
										<div className="tracking-info-label">Contact:</div>
										<div className="tracking-info-value">
											{modalResult?.recieverContact}
										</div>
									</div>
									<div className="tracking-info-row">
										<div className="tracking-info-label">Email:</div>
										<div className="tracking-info-value">
											{modalResult?.recieverEmail}
										</div>
									</div>
									<div className="tracking-info-row">
										<div className="tracking-info-label">Address:</div>
										<div className="tracking-info-value">
											{modalResult?.recieverAddress}
										</div>
									</div>
								</div>
								<div className="tracking-info-section">
									<h3>Shipment Information</h3>
									<div className="tracking-info-row">
										<div className="tracking-info-label">Item Description:</div>
										<div className="tracking-info-value">
											{modalResult?.itemDesc}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Product Name:</div>
										<div className="tracking-info-value">
											{modalResult?.productName}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Weight:</div>
										<div className="tracking-info-value">
											{modalResult?.weight}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Shipment Mode:</div>
										<div className="tracking-info-value">
											{modalResult?.shipmentMode}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Shipment Type:</div>
										<div className="tracking-info-value">
											{modalResult?.shipmentType}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Payment Mode:</div>
										<div className="tracking-info-value">
											{modalResult?.paymentMode}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Quantity:</div>
										<div className="tracking-info-value">
											{modalResult?.qty}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Total Freight:</div>
										<div className="tracking-info-value">
											{modalResult?.totalFreight}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Original Country:</div>
										<div className="tracking-info-value">
											{modalResult?.originalCountry}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Destination:</div>
										<div className="tracking-info-value">
											{modalResult?.destination}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">
											Consignment Movement:
										</div>
										<div className="tracking-info-value">
											{modalResult?.customStatus}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">
											Estimated Delivery Date:
										</div>
										<div className="tracking-info-value">
											{modalResult?.estimatedDeliveryDate}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Sent Date/Time:</div>
										<div className="tracking-info-value">
											{modalResult?.sentDateTime}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Pickup Time:</div>
										<div className="tracking-info-value">
											{modalResult?.pickupTime}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Pickup Date:</div>
										<div className="tracking-info-value">
											{modalResult?.pickupDate}
										</div>
									</div>
									<hr />
									<div className="tracking-info-row">
										<div className="tracking-info-label">Comments:</div>
										<div className="tracking-info-value">
											{modalResult?.comments}
										</div>
									</div>
									<hr />
								</div>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									paddingBottom: "2rem",
								}}>
								<QRCodeSVG
									value="https://goxpressway.com/"
									size={200}
									imageSettings={{
										src: `${Logo}`,
										width: 100,
									}}
								/>
							</div>
							<button onClick={handlePrint}>Click to Print</button>
						</div>
					</section>
				</Dialog>
			)}
		</Box>
	);
};

export default Form;
