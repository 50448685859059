import React, { useState, useEffect } from "react";
import { Box, Button, useTheme, Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../themes";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../apiConfig";
import PrintModal from "../../components/PrintModal";

const AllUsers = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "sendersName", headerName: "SenderName", width: 150 },
    { field: "sendersContact", headerName: "senderContact", width: 150 },
    { field: "sendersEmail", headerName: "SenderEmail", width: 100 },
    { field: "sendersAddress", headerName: "senderAddress", width: 100 },
    { field: "recieverName", headerName: "ReceiverName", width: 100 },
    { field: "recieverContact", headerName: "ReceiverContact", width: 100 },
    { field: "recieverEmail", headerName: "ReceiverEmail", width: 100 },
    { field: "recieverAddress", headerName: "ReceiverAddress", width: 100 },
    { field: "status", headerName: "Status", width: 100 },
    { field: "trackId", headerName: "trackID", width: 100 },
    { field: "itemDesc", headerName: "ItemDesc", width: 100 },
    { field: "productName", headerName: "ProductName", width: 100 },
    { field: "weight", headerName: "Weight", width: 100 },
    { field: "shipmentMode", headerName: "ShipmentMode", width: 100 },
    { field: "shipmentType", headerName: "ShipmentType", width: 100 },
    { field: "paymentMode", headerName: "PaymentMode", width: 100 },
    { field: "qty", headerName: "Qty", width: 100 },
    { field: "customStatus", headerName: "Custom-Status", width: 100 },
    { field: "totalFreight", headerName: "TotalFreight", width: 100 },
    { field: "originalCountry", headerName: "OriginalCountry", width: 100 },
    { field: "destination", headerName: "Destination", width: 100 },
    {
      field: "estimatedDeliveryDate",
      headerName: "EstimatedDeliveryDate",
      width: 100,
    },
    { field: "pickupTime", headerName: "PickupTime", width: 100 },
    { field: "sentDateTime", headerName: "SentDate/Time", width: 100 },
    { field: "pickupDate", headerName: "PickupDate", width: 100 },
    { field: "comments", headerName: "Comments", width: 100 },

    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          onClick={() => handleEdit(params.row.id)}
        >
          Edit
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      renderCell: (params) => (
        <Button
          type="submit"
          color="error"
          variant="contained"
          onClick={() => handleDelete(params.row.id)}
        >
          Delete
        </Button>
      ),
    },

    {
      field: "print",
      headerName: "Print",
      width: 100,
      renderCell: (params) => (
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          onClick={() => handlePrint(params.row)}
        >
          Print
        </Button>
      ),
    },
  ];

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handlePrint = (row) => {
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchRows = async () => {
      const actualData = await fetch(`${BASE_URL}/getAllUsers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }).then((response) => response.json());
      setRows(actualData.data);
      setLoading(false);
    };
    fetchRows();
  }, []);

  const handleEdit = (id) => {
    navigate(`/edit/${id}`);
  };

  const handleDelete = async (id) => {
    await fetch(`${BASE_URL}/delete/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        response.json();
        alert("Deleted Successfully!");
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Box m="20px">
        <Header title="Users" subtitle="List of all Current Users" />
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
          }}
        >
          {loading ? (
            <Box>Loading...</Box>
          ) : (
            <DataGrid rows={rows} columns={columns} />
          )}
        </Box>
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <PrintModal
            selectedRow={selectedRow}
            handleClose={handleCloseModal}
            isModalOpen={isModalOpen}
          />
        </Modal>
      </Box>
    </>
  );
};

export default AllUsers;
